import { Vue, Component, Prop } from 'vue-property-decorator';
import { ScreenText } from '@/lang/ScreenText';
import APP_CONST from '@/constants/AppConst';
import siteSessionStore from '@/store/modules/siteSessionStore';
import APP_UTILITIES from '@/utilities/commonFunctions';
import DatepickerComponent from '@/ui-components/datepicker/DatepickerComponent.vue';
import DropdownList from '@/ui-components/dropdownListBx/DropdownList.vue';

@Component({
  components: {
    'datepicker': DatepickerComponent,
    'dropdown-component': DropdownList
  }
})
export default class SiteSessionComponent extends Vue {

  public isSiteAccordionActive:boolean = false;

  private objScreenText: ScreenText = new ScreenText();

  private addSiteDetails:any = siteSessionStore.siteSessionDetails;

  private states: any = APP_UTILITIES.getStatesShort(APP_CONST.COUNTRY_ID);
  private removedStates: any = APP_CONST.REMOVED_STATES;
  public openDropSiteStatus: boolean = false;
  public timeList: any = [];
  public enableToggle: boolean = true;
  public showAgeRangePop: boolean = false;
  public showAgeAsOfPop: boolean = false;
  public showAutoEnrollPop: boolean = false;
  public selectDaysPop: boolean = false;
  public currentActiveIndex:number = -1;
  public daysValues:Object = {
    'Sun': 'Su',
    'Mon': 'M',
    'Tue': 'T',
    'Wed': 'W',
    'Thu': 'Th',
    'Fri': 'F',
    'Sat': 'S'
  };
  public weekFullValues:any = [
    {'Sun': 0},
    {'Mon': 1},
    {'Tue': 2},
    {'Wed': 3},
    {'Thu': 4},
    {'Fri': 5},
    {'Sat': 6}
  ];

  @Prop()
  initialSiteStartDate!:any;

  @Prop()
  initialSiteEndDate!:any;

  @Prop()
  programStartDate!:any;

  @Prop()
  programEndDate!:any;

  @Prop()
  isEditMode!:boolean;

  public getScreenText(key: string): string {
    return this.objScreenText.getScreenText(key);
  }

  /* istanbul ignore next */
  get isSiteEditMode() {
    return this.$route.path == '/account/program/site';
  }

  /* istanbul ignore next */
  get isSiteAddMode() {
    return this.$route.path == '/account/program/sites/createsite';
  }

  /* istanbul ignore next */
  get isSiteViewMode() {
    return (!this.isEditMode && this.isSiteEditMode);
  }

  /* istanbul ignore next */
  get isSiteEditModeOn() {
    return (this.isEditMode && this.isSiteEditMode);
  }

  /* istanbul ignore next */
  get showSiteDeleteBtn() {
    if (this.isSiteAddMode && this.addSiteDetails.length > APP_CONST.ONE) {
      return true;
    }
    else if (this.isSiteEditMode || (this.isSiteAddMode && this.addSiteDetails.length <= APP_CONST.ONE) || (!this.isEditMode && this.isSiteEditMode)) {
      return false;
    }
    else {
      return true;
    }
  }

  /* istanbul ignore next */
  get isSiteStartDatePassed() {
    const startDate:any = this.addSiteDetails[0].startDate.value
      ? new Date(APP_UTILITIES.formatShortDateFullYear(new Date(this.initialSiteStartDate).toString())).getTime()
      :'';
    const today:any = new Date(APP_UTILITIES.formatShortDateFullYear(new Date().toDateString())).getTime();
    return startDate
      ? startDate<=today
      : false;
  }

  /* istanbul ignore next */
  get isSiteEndDatePassed() {
    const endDate:any = this.addSiteDetails[0].endDate.value
      ? new Date(APP_UTILITIES.formatShortDateFullYear(new Date(this.initialSiteEndDate).toString())).getTime()
      :'';
    const today:any = new Date(APP_UTILITIES.formatShortDateFullYear(new Date().toDateString())).getTime();
    return endDate
      ? endDate<=today
      : false;
  }

  /* istanbul ignore next */
  get isScholarCapacityDisabled() {
    return this.isSiteStartDatePassed && this.addSiteDetails[0].autoEnroll.value;
  }

  /* istanbul ignore next */
  get siteStartDateDisabled() {
    return this.isSiteEditModeOn && this.isSiteStartDatePassed;
  }

  /* istanbul ignore next */
  get siteEndDateDisabled() {
    return this.isSiteEditModeOn && this.isSiteEndDatePassed;
  }

  get siteSessionStatesDD() {
    return siteSessionStore.siteSessionStatesDD;
  }

  openDropdown(site:any) {
    site.dropDownStatus = site.dropDownStatus
      ? APP_CONST.FALSE
      : APP_CONST.TRUE;
  }

  public validateTime(start_time: string, end_time: string) {
    const start_hh_mm = APP_UTILITIES.convertTime12to24(start_time).split(':');
    const end_hh_mm = APP_UTILITIES.convertTime12to24(end_time).split(':');
    const startHour = +start_hh_mm[0], startMinute = +start_hh_mm[1], endHour = +end_hh_mm[0], endMinute = +end_hh_mm[1];
    const startTimeObject: any = new Date();
    startTimeObject.setHours(startHour, startMinute, APP_CONST.ZERO, APP_CONST.ZERO);
    const endTimeObject: any = new Date();
    endTimeObject.setHours(endHour, endMinute, APP_CONST.ZERO, APP_CONST.ZERO);
    const diffMins = APP_UTILITIES.diff_minutes(endTimeObject, startTimeObject);
    if ((diffMins <= 30) || (start_time && !end_time) || (!start_time && end_time)) {
      return true;
    }
    else {
      return false;
    }
  }

  generateTimeInterval() {
    const min_interval = 15;
    let zeroth_time = 0;
    const ap = ['AM', 'PM'];
    for (let i = 0; zeroth_time < 24 * 60; i++) {
      const hh = Math.floor(zeroth_time / 60);
      const mm = (zeroth_time % 60);
      let hr;
      if (hh % 12 == 0) {
        hr = 12;
      }
      else {
        hr = hh % 12;
      }
      this.timeList[i] = ('' + (hr)).slice(-2) + ':' + ('0' + mm).slice(-2) + ' ' + ap[Math.floor(hh / 12)];
      zeroth_time = zeroth_time + min_interval;
    }
  }

  dropDownHandler(site:any, type: string, time: string) {
    if (time === 'Select') {

      site.startTime.value = time;
      site.endTime.value = time;
      site.startTime.errorStatus = false;
      site.endTime.errorStatus = false;
    }
    else {
      if (type == 'startTime') {
        site.startTime.errorStatus = false;
        site.startTime.value = time;
        const element: any = document.getElementById('startTime');
        if (element) {
          element.innerHTML = site.startTime.value;
        }
        site.endTime.value = site.endTime.value === 'Select'
          ? time
          : site.endTime.value;
        site.endTime.value = this.validateTime(site.startTime.value, site.endTime.value)
          ? this.add30Min(APP_UTILITIES.convertTime12to24(time))
          : site.endTime.value;
      }
      else {
        site.endTime.errorStatus = false;
        site.endTime.value = time.trim();
        const element: any = document.getElementById('endTime');
        if (element) {
          element.innerHTML = site.endTime.value;
        }

        site.startTime.value = site.startTime.value === 'Select'
          ? time
          : site.startTime.value;
        site.startTime.value = this.validateTime(site.startTime.value, site.endTime.value)
          ? this.sub30Min(APP_UTILITIES.convertTime12to24(time))
          : site.startTime.value;
      }
    }
  }

  replaceAMPM(time: string) {
    time = time.replace('PM', '');
    time = time.replace('AM', '');
    return time;
  }

  add30Min(time: string) {
    const theAdd = new Date(APP_UTILITIES.getFullDate(new Date()) + ' ' + this.replaceAMPM(time));
    const finalDate = theAdd.setMinutes(theAdd.getMinutes() + 30);
    return (new Date(finalDate).toLocaleTimeString('en-US', { hour: 'numeric', hour12: true, minute: 'numeric' }));
  }

  sub30Min(time: string) {
    const theAdd = new Date(APP_UTILITIES.getFullDate(new Date()) + ' ' + this.replaceAMPM(time));
    const finalDate = theAdd.setMinutes(theAdd.getMinutes() - 30);
    return (new Date(finalDate).toLocaleTimeString('en-US', { hour: 'numeric', hour12: true, minute: 'numeric' }));
  }

  startTimeChange(ev: any, site:any) {

    const time: string = ev.target.textContent.trim().toUpperCase().replace(/ /g, '');
    if (ev.target.textContent) {
      if (APP_UTILITIES.validateHhMm12Format(time) || APP_UTILITIES.validateHhMm12FormatwithoutColon(time)) {
        site.startTime.errorStatus = false;
        const convertTimeintoColon = APP_UTILITIES.convertTime(time);
        site.startTime.value = APP_UTILITIES.checkTimeIs24(convertTimeintoColon)
          ? APP_UTILITIES.convert12hrFormateTime(convertTimeintoColon)
          : convertTimeintoColon;
        const element: any = document.getElementById('startTime');
        if (element) {
          element.innerHTML = site.startTime.value;
        }
        site.endTime.value = this.validateTime(site.startTime.value, site.endTime.value)
          ? this.add30Min(APP_UTILITIES.convertTime12to24(convertTimeintoColon))
          : site.endTime.value;
      }
      else {
        site.startTime.errorStatus = true;
      }
    }
    else {
      if (APP_UTILITIES.validateHhMm12Format(time)) {
        site.startTime.errorStatus = false;
        site.startTime.value = APP_UTILITIES.checkTimeIs24(time)
          ? APP_UTILITIES.convert12hrFormateTime(time)
          : time;
      }
      else {
        site.startTime.value = '';
        site.startTime.errorStatus = true;
      }
    }
  }

  endTimeChange(ev: any, site:any) {

    const time: string = ev.target.textContent.trim().toUpperCase().replace(/ /g, '');
    if (ev.target.textContent) {
      if (APP_UTILITIES.validateHhMm12Format(time) || APP_UTILITIES.validateHhMm12FormatwithoutColon(time)) {
        site.startTime.errorStatus = false;
        const convertTimeintoColon = APP_UTILITIES.convertTime(time);
        site.endTime.value = APP_UTILITIES.checkTimeIs24(convertTimeintoColon)
          ? APP_UTILITIES.convert12hrFormateTime(convertTimeintoColon)
          : convertTimeintoColon;
        const element: any = document.getElementById('endTime');
        if (element) {
          element.innerHTML = site.endTime.value;
        }
        site.startTime.value = this.validateTime(site.startTime.value, site.endTime.value)
          ? this.sub30Min(APP_UTILITIES.convertTime12to24(convertTimeintoColon))
          : site.startTime.value;
      }
      else {
        site.endTime.errorStatus = true;
      }
    }
    else {
      if (APP_UTILITIES.validateHhMm12Format(time)) {
        site.endTime.errorStatus = false;
        site.endTime.value = APP_UTILITIES.checkTimeIs24(time)
          ? APP_UTILITIES.convert12hrFormateTime(time)
          : time;
      }
      else {
        site.endTime.value = '';
        site.endTime.errorStatus = true;
      }
    }
  }

  public addRemoveSiteDay(site:any, day: string) {
    if (site.siteDays.value.includes(day)) {
      const dayIndex = site.siteDays.value.indexOf(day);
      if (dayIndex > -1) {
        site.siteDays.value.splice(dayIndex, 1);
      }
    }
    else {
      site.siteDays.value.push(day);
    }
  }

  toggleDrop(site:any, type: string, close?:boolean) {
    if (type == 'startTime') {
      /* istanbul ignore next */
      if (site.endTime.dropDownStatus) {
        site.endTime.dropDownStatus = false;
      }
      site.startTime.dropDownStatus = site.startTime.dropDownStatus
        ? APP_CONST.FALSE
        : APP_CONST.TRUE;
    }
    else {
      /* istanbul ignore next */
      if (site.startTime.dropDownStatus) {
        site.startTime.dropDownStatus = false;
      }
      site.endTime.dropDownStatus = site.endTime.dropDownStatus
        ? APP_CONST.FALSE
        : APP_CONST.TRUE;
    }
  }

  timeFormat ($event: KeyboardEvent) {
    const allowedAlphabets = ['A', 'a', 'P', 'p', 'M','m'];
    const keysAllowed: string[] = ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9', ':', ' ', 'A', 'a', 'P', 'p', 'M','m'];
    const keyPressed: string = $event.key;
    const eventTarget:any = $event.target;
    if (!keysAllowed.includes(keyPressed)  || (allowedAlphabets.includes(keyPressed) && eventTarget.textContent.toLowerCase().includes(keyPressed.toLowerCase()))) {
      $event.preventDefault();
    }
  }

  checkLength($event: any) {
    if (($event.target.textContent.length > 8) && ($event.keyCode !== 8) && ($event.keyCode !== 37) && ($event.keyCode !== 39)) {
      $event.preventDefault();
    }
    if ($event.keyCode == 13) {
      $event.preventDefault();
    }
  }

  public onInputStartTime($event: any) {
    if (($event.target.textContent.length !== 8) && ($event.keyCode !== 37) && ($event.keyCode !== 39)) {
      $event.preventDefault();
      return false;
    }
  }

  onlyNumber($event : any) {

    const keyCode = ($event.keyCode
      ? $event.keyCode
      : $event.which);
    if ((keyCode < 48 || keyCode > 57)) {
      $event.preventDefault();
    }
  }

  deleteSite(siteIndex:number) {
    this.addSiteDetails.splice(siteIndex, 1);
  }

  checkForCorrectDate(site:any) {
    site.endDate.errorStatus= false;
    site.startDate.errorStatus= false;
    if (site.startDate.value && site.endDate.value && new Date(APP_UTILITIES.formatShortDateFullYear(site.endDate.value)).getTime()<=new Date(APP_UTILITIES.formatShortDateFullYear(site.startDate.value)).getTime()) {
      site.endDate.errorText= 'Make sure your site start date is before the end date.';
      site.endDate.errorStatus= true;
    }
    if (site.startDate.value && this.programStartDate && new Date(APP_UTILITIES.formatShortDateFullYear(site.startDate.value)).getTime()<new Date(APP_UTILITIES.formatShortDateFullYear(this.programStartDate)).getTime()) {
      site.startDate.errorText= 'Date cannot fall before the Program Start Date.';
      site.startDate.errorStatus= true;
    }
    if (site.startDate.value && this.programStartDate && new Date(APP_UTILITIES.formatShortDateFullYear(site.startDate.value)).getTime()>new Date(APP_UTILITIES.formatShortDateFullYear(this.programEndDate)).getTime()) {
      site.startDate.errorText= 'Session date exceeds program end date.';
      site.startDate.errorStatus= true;
    }
    if (site.endDate.value && this.programEndDate && new Date(APP_UTILITIES.formatShortDateFullYear(site.endDate.value)).getTime()>new Date(APP_UTILITIES.formatShortDateFullYear(this.programEndDate)).getTime()) {
      site.endDate.errorText= 'Session date exceeds program end date.';
      site.endDate.errorStatus= true;
    }
    if (site.endDate.value && this.programEndDate && new Date(APP_UTILITIES.formatShortDateFullYear(site.endDate.value)).getTime()<new Date(APP_UTILITIES.formatShortDateFullYear(this.programStartDate)).getTime()) {
      site.endDate.errorText= 'Date cannot fall before the Program Start Date.';
      site.endDate.errorStatus= true;
    }
  }

  setDefaultAgeAsOfDate(site:any) {
    site.ageAsOf.value= site.startDate.value;
  }

  changeErrorStatus(site:any) {
    site.ageTo.errorStatus=false;
    site.ageFrom.errorStatus=false;
  }

  /**
     * Return formatted date
     *
     * @param date
     * @returns
     */
  formattedDate(date:any) {
    return APP_UTILITIES.getFullDate(date);
  }

  /**
   * Format days
   *
   * @param days
   * @returns
   */
  formattedDays(days:any) {
    const finalDaysArr:any = [];
    if (days.length) {
      days.forEach((day:string)=>{
        finalDaysArr.push(this.getKeyByValue(this.daysValues, day));
      });
    }
    return this.sortFullWeekDaysFnc(finalDaysArr);
  }

  /**
   * Return key by value
   *
   * @param object
   * @param value
   * @returns
   */
  getKeyByValue(object:any, value:any) {
    return Object.keys(object).find(key => object[key] === value);
  }

  sortFullWeekDaysFnc(finalDaysArr:any) {
    if (finalDaysArr.length) {
      const selectedDaysFullArr= finalDaysArr;
      this.weekFullValues.forEach((el:any) => {
        selectedDaysFullArr.forEach((elem:any)=>{
          if (elem == Object.keys(el)) {
            const index = selectedDaysFullArr.indexOf(elem);
            if (index !== -1) {
              selectedDaysFullArr[index] = Object.values(el);
            }
          }
        });
      });
      let weekDaysFullArr = selectedDaysFullArr.join();
      weekDaysFullArr = JSON.parse('[' + weekDaysFullArr + ']');
      weekDaysFullArr.sort(function(a:any, b:any) {
        if (a < b) {
          return -1;
        }
        if (a > b) {
          return 1;
        }
        return 0;
      });
      let sortedDays:any = [];
      weekDaysFullArr.forEach((index:any)=>{
        switch(index) {
          case 0:
            sortedDays.push('Sun');
            break;
          case 1:
            sortedDays.push('Mon');
            break;
          case 2:
            sortedDays.push('Tue');
            break;
          case 3:
            sortedDays.push('Wed');
            break;
          case 4:
            sortedDays.push('Thu');
            break;
          case 5:
            sortedDays.push('Fri');
            break;
          case 6:
            sortedDays.push('Sat');
            break;
        }
      });
      sortedDays = sortedDays.toString();
      finalDaysArr = sortedDays;
      finalDaysArr = finalDaysArr.replace(/,/g, ', ');
      return finalDaysArr;
    }
    else {
      return '--';
    }
  }

  beforeMount() {
    this.states = APP_UTILITIES.removeStates(this.states, this.removedStates);
    this.states && this.states.forEach((element: any) => {
      element.value = element.state_code;
    });
    this.states = APP_UTILITIES.sortAscending(this.states, 'state_code');
    this.siteSessionStatesDD && this.siteSessionStatesDD.forEach((element: any) => {
      element.dropdownList = this.states;
    });
    siteSessionStore.mutateStatesDD(this.siteSessionStatesDD);
  }

  mounted() {
    this.generateTimeInterval();
  }

  selectState(state:{id: number; value: string}, index: number) {
    this.addSiteDetails[index].state.value = state.value;
    this.siteSessionStatesDD[index].value = state.value;
    this.siteSessionStatesDD[index].id = state.id;
  }

  destroyed() {
    siteSessionStore.resetDropDown();
  }
}